import React, { useContext, useEffect, useState } from "react"
// import SEO from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/Seo"

import { Helmet } from "react-helmet"
import SEO from "gatsby-theme-rothenberger/src/@yumgmbh/gatsby-theme-yum-components/components/modules/Seo"
import Logo from "gatsby-theme-rothenberger/src/@yumgmbh/gatsby-theme-yum-components/components/modules/Logo"
import Link from "gatsby-theme-rothenberger/src/@yumgmbh/gatsby-theme-yum-components/components/atoms/Link"

const websitesRegionsMap = require("gatsby-theme-rothenberger/config/websiteRegions")
const websiteRegionsMapping = websitesRegionsMap["websiteRegionsMapping"]

import { changeLanguage } from "i18next"

const Layout = ({ children }) => {
  const language = "en"
  const [cookieConsentLight, setCookieConsentLight] = useState(false)

  // set i18n-language
  // set klaro-language
  useEffect(() => {
    changeLanguage(language)

    // check for impressum ands datenschutz + dont block interaction with the site
    if (
      window.location.pathname.indexOf("/privacy") !== -1 ||
      window.location.pathname.indexOf("/imprint") !== -1
    ) {
      setCookieConsentLight(true)
    }

    if (typeof window !== "undefined") {
      if (window.klaro && window.klaro.render && window.klaroConfig) {
        //window.klaro.defaultConfig.lang = language

        setTimeout(() => {
          window.klaro.render({
            ...window.klaroConfig,
            ...{ lang: language },
          })
          if (window.klaro.getManager) {
            let manager = window.klaro.getManager()
            manager.applyConsents()
          }
        }, 50)
      }
    }
  }, [language, children])

  return (
    <>
      <SEO title="ROTHENBERGER" language={language} />
      <Helmet
        htmlAttributes={{ lang: language }}
        bodyAttributes={{
          class: `theme-default ${cookieConsentLight ? "klaro-light" : ""}`,
        }}
      >
        <script type="application/ld+json">
          {`{
            "@context" : "https://schema.org",
            "@type" : "WebSite",
            "name" : "ROTHENBERGER",
            "url" : "https://rothenberger.com/"
          }`}
        </script>

        {websiteRegionsMapping &&
          websiteRegionsMapping.length > 0 &&
          websiteRegionsMapping.map((reg) => {
            return (
              <link
                key={reg.hrefLang}
                rel="alternate"
                href={reg.url}
                hrefLang={reg.hrefLang}
              />
            )
          })}
        <link
          rel="alternate"
          href="https://rothenberger.com/websites/"
          hrefLang="x-default"
        />
        <script
          type="text/plain"
          data-name="googleanalytics"
          data-type="application/javascript"
        >
          {`
            (function(i, s, o, g, r, a, m) {
              i['GoogleAnalyticsObject'] = r;
              i[r] = i[r] || function() {
                (i[r].q = i[r].q || []).push(arguments)
              }, i[r].l = 1 * new Date();
              a = s.createElement(o),
                m = s.getElementsByTagName(o)[0];
              a.async = 1;
              a.src = g;
              m.parentNode.insertBefore(a, m)
            })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

            ga('create', '${process.env.GATSBY_GA_KEY}', 'auto');
            ga('set', 'anonymizeIp', true);
            ga('send', 'pageview');
          `}
        </script>

        <script
          type="text/plain"
          data-name="adformtracking"
          data-type="application/javascript"
        >
          {` window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);
            window._adftrack.push({
                HttpHost: 'track.adform.net',
                pm: 2861695,
                divider: encodeURIComponent('|'),
                pagename: encodeURIComponent('Global Tracking')
            });
            (function () { var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })();
          `}
        </script>

        <script
          type="text/plain"
          data-name="facebookpixel"
          data-type="application/javascript"
        >
          {`!(function (f, b, e, v, n, t, s) {
              if (f.fbq) return;
              n = f.fbq = function () {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
              };
              if (!f._fbq) f._fbq = n;
              n.push = n;
              n.loaded = !0;
              n.version = "2.0";
              n.queue = [];
              t = b.createElement(e);
              t.async = !0;
              t.src = v;
              s = b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t, s);
            })(
              window,
              document,
              "script",
              "https://connect.facebook.net/en_US/fbevents.js"
            );
            fbq("init", "325618258312207");
            fbq("track", "PageView");
          `}
        </script>

        <script
          type="text/plain" // @TODO: klaro integration, move GTM-KEY to env files
          data-name="googleanalytics"
          data-type="application/javascript"
        >
          {` (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${process.env.GATSBY_GTM_KEY}');`}
        </script>
      </Helmet>
      <div className="relative hidden w-full bg-white border-b border-gray-100 md:block">
        <div className="flex flex-row py-4 mx-auto max-w-screen-2xl">
          <Logo className="h-12 px-4 pt-4 pb-2" />
        </div>
      </div>
      <main>{children}</main>
      <footer className="text-gray-400 bg-gray-800">
        <div className="mx-auto text-sm max-w-screen-2xl px-grid">
          <div className="w-full border-t border-gray-400 px-grid" />
          <div className="flex flex-row flex-wrap justify-between py-4 text-xs -mx-grid">
            <div className="flex flex-row flex-wrap w-full xl:w-auto">
              <a
                key="cookieManager"
                className="py-4 px-grid hover:text-white whitespace-nowrap"
                onClick={() => {
                  return window.klaro.show({
                    ...window.klaroConfig,
                    ...{ lang: language },
                  })
                }}
                href="javascript:;"
              >
                Manage cookies
              </a>
              <Link
                className="py-4 px-grid show-when-consent hover:text-white whitespace-nowrap"
                link="/imprint"
              >
                Imprint
              </Link>
              <Link
                className="py-4 px-grid show-when-consent hover:text-white whitespace-nowrap"
                link="/privacy"
              >
                Privacy
              </Link>
            </div>
            <span className="block w-full py-4 show-when-consent px-grid xl:order-first xl:w-auto">
              © {new Date().getFullYear()} ROTHENBERGER Werkzeuge GmbH
            </span>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Layout
