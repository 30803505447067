import React from "react"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const Logo = ({ className }) => {
  return (
    <Link
      className={`block ${className}`}
      link="/"
      isCrossWebsiteLink={true}
    >
      <Image
        className="block w-auto h-full"
        forceLoad={true}
        image={{
          filename: `${process.env.GATSBY_ORIGINAL_HOST}/ro-logo.svg`,
          alt: "Rothenberger",
        }}
      />
    </Link>
  )
}

export default Logo
